<template>
  <div class="navbar-expand-lg nav-light navbar__login-wrapper">
    <div class="container-fluid container-fluid-login">
      <div class="w-100 d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <a class="navbar-brand" @click="handleBackUrl">
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.6666 9.66667V12.3333H5.66663L13 19.6667L11.1066 21.56L0.546631 11L11.1066 0.440002L13 2.33334L5.66663 9.66667H21.6666Z"
                fill="#F2F2F2"
              />
            </svg>
          </a>
          <h6 class="title-navbar-login">
            Atur Ulang Kata Sandi
          </h6>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="card card__form-login">
      <div class="card-body card__body-form">
        <h6 class="title-form">
          Masukkan email dan no. hp yang terdaftar. Kami akan mengirimkan link verifikasi untuk
          mengatur ulang kata sandi.
        </h6>
        <form @submit.prevent="handleFormSubmit" class="needs-validation" novalidate>
          <div class="input-group input__group mb-4">
            <span id="basic-addon1" class="input-group-text input__text-login">
              <icon-user-gray />
            </span>
            <input
              type="text"
              class="form-control form__input"
              :class="{ 'invalid-input': validations.email.error }"
              placeholder="Email/No. HP"
              v-model="email"
              @keyup="handleKeyupFormInput"
            />
            <div v-if="validations.email.error" class="invalid-feedback d-block">
              {{ validations.email.message }}
            </div>
          </div>
          <div class="input-group input__group">
            <p class="text-muted note-input_group">
              Jika nomor ponsel maka link akan dikirim melalui wa.
            </p>
          </div>
          <div class="form-group signin-group">
            <button type="submit" :disabled="disabledButtonSubmit" class="btn btn-sm btn-warning login-button w-100">
              Kirim
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import swal from 'sweetalert2'

import IconUserGray from '../components/Icon/IconUserGray.vue'

export default {
  name: 'ForgotPassword',
  components: {
    IconUserGray
  },
  data() {
    return {
      email: '',
      validations: {
        email: {
          error: false,
          message: null
        }
      }
    }
  },
  computed: {
    disabledButtonSubmit() {
      if (this.email === '' || this.validations.email.error === true) {
        return true
      }
      return false
    }
  },
  methods: {
    handleBackUrl() {
      this.$router.go(-1)
    },
    handleKeyupFormInput() {
      if (this.email === '') {
        this.validations.email.error = true
        this.validations.email.message = 'Email atau No. HP tidak boleh kosong'
      } else {
        this.validations.email.error = false
        this.validations.email.message = null
      }
    },
    handleFormSubmit() {
      swal.fire({
        title: '', // add html attribute if you want or remove
        text: 'Mohon Menunggu',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: function() {
          swal.showLoading()
        }
      })
      this.$http
        .post(`${process.env.VUE_APP_API_URL}reset-password/create`, {
          username: this.email
        })
        .then(() => {
          swal.close()
          swal.hideLoading()
          this.$store.dispatch(
            'notification/success',
            'Link untuk reset password berhasil dikirim, mohon periksa Whatsapp atau email anda'
          )
        })
        .catch(error => {
          swal.close()
          swal.hideLoading()

          if (error.response.status === 404 && error.response.data.message === 'User not found') {
            this.$store.dispatch('notification/error', 'Data user tidak ditemukan')
          } else {
            this.$store.dispatch('notification/error', error)
          }
        })
    }
  }
}
</script>
